define("additive-mc/services/ui-transaction-manager", ["exports", "additive-mc/config/environment", "@glimmer/tracking", "lodash.clonedeep", "@additive-apps/ui/utils/dom-util", "@ember/service"], function (_exports, _environment, _tracking, _lodash, _domUtil, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Service that creates and manages transactions for the UI.
   *
   * TODO: Move to UI Addon when it's ready.
   *
   * @class UiFilterService
   */
  let UiTransactionManager = _exports.default = (_class = class UiTransactionManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      /**
       * The current transaction id
       *
       * @property transactionId
       * @type {String}
       */
      _initializerDefineProperty(this, "transactionId", _descriptor5, this);
      _initializerDefineProperty(this, "changedModels", _descriptor6, this);
      _initializerDefineProperty(this, "isLoading", _descriptor7, this);
      _initializerDefineProperty(this, "modelId", _descriptor8, this);
    }
    get baseUrl() {
      return `${_environment.default.APP.apiBaseHost}/${this.organizationId}/content-wizard/transactions`;
    }
    get organizationId() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }
    get hasChanges() {
      return this.changedModels.length > 0;
    }
    get hasTransactionId() {
      return this.transactionId ? true : false;
    }
    get currentLocale() {
      return this.uiAppSettings.currentLocale;
    }
    get defaultLanguage() {
      return this.currentUser.currentOrganization.language;
    }
    async beginTransaction(body) {
      var _json$transaction;
      const response = await this.authenticatedFetch.fetch(this.baseUrl, {
        method: 'POST',
        body: JSON.stringify(body)
      });
      const json = await response.json();
      this.modelId = body === null || body === void 0 ? void 0 : body.id;
      this.transactionId = json === null || json === void 0 || (_json$transaction = json.transaction) === null || _json$transaction === void 0 ? void 0 : _json$transaction.id;
      await (0, _domUtil.nextTick)();
    }
    async commitTransaction() {
      if (!this.transactionId) {
        return;
      }
      const language = this.currentLocale || this.defaultLanguage;
      const url = `${this.baseUrl}/${this.transactionId}/commit`;
      const response = await this.authenticatedFetch.fetch(url, {
        method: 'POST',
        headers: {
          'Accept-Language': language
        }
      });
      if ((response === null || response === void 0 ? void 0 : response.status) === 204) {
        this._cleanUp();
        return;
      }
      const status = response === null || response === void 0 ? void 0 : response.status;
      const json = await (response === null || response === void 0 ? void 0 : response.json());
      if (!response.ok) {
        json.status = status;
        return json;
      }
      this._cleanUp();
      return json;
    }
    async discardTransaction() {
      if (!this.transactionId) {
        return;
      }
      const url = `${this.baseUrl}/${this.transactionId}`;
      await this.authenticatedFetch.fetch(url, {
        method: 'DELETE'
      });
      this.changedModels.forEach(model => {
        typeof model.onTransactionDiscard === 'function' && model.onTransactionDiscard();
      });
      this._cleanUp();
    }
    async createModelRecord(model) {
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionCreateUrl}`;
      const body = {
        id: model.id
      };
      if (model._internalModel.modelName === 'trigger-email-campaign') {
        body['trigger'] = model.trigger;
        body['name'] = model.name;
      }
      await this.authenticatedFetch.fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
      });
      this._updateChangedModels({
        model
      });
    }
    async deleteModelRecord(model) {
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}`;
      await this.authenticatedFetch.fetch(url, {
        method: 'DELETE'
      });
      model.active = false;
      // Sets isDeleted to true
      model.deleteRecord();
      this._updateChangedModels({
        model
      });
      model.unloadRecord();
    }
    async publish(model, language) {
      if (!this.transactionId) {
        return;
      }
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}/publish`;
      await this.authenticatedFetch.fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          language: language
        })
      });
      let publishedLanguages = (0, _lodash.default)(model.get('publishedLanguages')) || [];
      publishedLanguages.push(language);
      model.publishedLanguages = publishedLanguages;
    }
    async unpublish(model, language) {
      if (!this.transactionId) {
        return;
      }
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}/publish`;
      await this.authenticatedFetch.fetch(url, {
        method: 'DELETE',
        body: JSON.stringify({
          language: language
        })
      });
      model.publishedLanguages = model.publishedLanguages.filter(publishedLanguage => publishedLanguage !== language);
    }
    async schedulePublication(model, property, value) {
      if (!this.transactionId) {
        return;
      }
      const data = {
        isStartsAtEnabled: model.isStartsAtEnabled,
        startsAt: model.startsAt,
        isEndsAtEnabled: model.isEndsAtEnabled,
        endsAt: model.endsAt
      };
      data[property] = value;
      model.isStartsAtEnabled = data.isStartsAtEnabled;
      model.startsAt = data.startsAt;
      model.endsAt = data.endsAt;
      model.isEndsAtEnabled = data.isEndsAtEnabled;
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}`;
      await this.authenticatedFetch.fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
    }
    async changeModelProperty(model, property, value, modelKey, urlSuffix, customBody, oldReadableValue, newReadableValue) {
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}${urlSuffix ? `/${urlSuffix}` : ''}`;
      if (typeof value === 'string' && value.length === 0) {
        value = null;
      }
      const oldModelCopy = this._findInternalModelCopy(model);
      // Skip request, if no changes were made
      if ((oldModelCopy === null || oldModelCopy === void 0 ? void 0 : oldModelCopy[property]) === value) {
        return;
      }
      const data = {
        method: 'PATCH',
        headers: {
          'Accept-Language': model === null || model === void 0 ? void 0 : model.language
        },
        body: JSON.stringify({
          [property]: value
        })
      };
      if (customBody) {
        data.body = JSON.stringify(customBody);
      }
      if (!(model !== null && model !== void 0 && model.language)) {
        delete data.headers;
      }
      if (modelKey) {
        let item = null;
        let oldValue = null;
        let newValue = null;
        if (Array.isArray(model[modelKey])) {
          item = model[modelKey].find(item => item.key === property);
          oldValue = item.value;
          item.value = value;
          newValue = item.value;
        } else {
          item = model[modelKey][property];
          oldValue = item;
          item = value;
          newValue = item;
        }
        this._updateChangedModels({
          model,
          modelKey,
          property,
          oldValue,
          newValue
        });
      } else if (newReadableValue || oldReadableValue) {
        const newValue = newReadableValue;
        const oldValue = oldReadableValue;
        model[property] = value;
        this._updateChangedModels({
          model,
          property,
          newValue,
          oldValue
        });
      } else {
        model[property] = value;
        this._updateChangedModels({
          model,
          property
        });
      }
      await this.authenticatedFetch.fetch(url, data);
    }
    async changeModelState(model) {
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}/activate`;
      const isActive = (model === null || model === void 0 ? void 0 : model.active) || model.state === 'active';
      if ('state' in model) {
        model.state = isActive ? 'inactive' : 'active';
        this._updateChangedModels({
          model,
          property: 'state'
        });
      }
      if ('active' in model) {
        model.active = isActive ? false : true;
        this._updateChangedModels({
          model,
          property: 'active'
        });
      }
      await this.authenticatedFetch.fetch(url, {
        method: isActive ? 'DELETE' : 'POST'
      });
    }
    async deleteLanguage(model, language, adapterOptions) {
      this.isLoading = true;
      const availableLanguages = model.availableLanguages;
      const languageIndex = availableLanguages.indexOf(language);
      if (languageIndex) {
        availableLanguages.splice(languageIndex, 1);
      }

      //  Find/Load all languages of the model and update availableLanguages
      await this._updateAvailableLanguages(model, availableLanguages, adapterOptions);

      // Check if current model is language which should be deleted
      if (language !== model.language) {
        // Find model in changed models and update values
        const changedModel = this._findInternalModelCopy(model, language);
        if (changedModel) {
          Object.keys(changedModel).forEach(key => {
            if (key !== 'id' && key !== 'modelName' && key !== 'isDeleted' && key !== 'isDeletedLanguage' && key !== 'isNew') {
              model[key] = changedModel[key];
            }
          });
        } else {
          // Model not found rollback and reload the model
          model.rollbackAttributes();
          this.uiAppSettings.setLocale(language);
          model.language = language;
          let _adapterOptions = {
            campaignId: this.modelId,
            type: model._internalModel.modelName,
            useNewApi: true
          };
          _adapterOptions = _objectSpread(_objectSpread({}, _adapterOptions), adapterOptions);
          await model.reload({
            adapterOptions: _adapterOptions
          });
        }
      }
      this._updateChangedModels({
        model,
        language,
        property: 'language',
        isRemoveLanguage: true
      });
      const url = `${this.baseUrl}/${this.transactionId}/${model.transactionUrl}/${language}`;
      await this.authenticatedFetch.fetch(url, {
        method: 'DELETE'
      });
      this.isLoading = false;
    }
    async addLanguage(model, locale, adapterOptions) {
      this.isLoading = true;
      this.uiAppSettings.setLocale(locale);
      model.language = locale;
      this._updateChangedModels({
        model,
        isAddLanguage: true
      });
      const availableLanguages = model.availableLanguages;

      //  Find/Load all languages of the model and update availableLanguages
      await this._updateAvailableLanguages(model, availableLanguages, adapterOptions);

      // Switch to new language
      const index = this.changedModels.findIndex(existingModel => existingModel.modelName === model._internalModel.modelName && existingModel.id === model.id && existingModel.language === locale && existingModel.isDeletedLanguage === false);

      // Find model in changed models and apply changes
      if (index !== -1) {
        Object.keys(this.changedModels[index]).forEach(key => {
          if (key !== 'id' && key !== 'modelName' && key !== 'isDeleted' && key !== 'isDeletedLanguage' && key !== 'isNew') {
            model[key] = this.changedModels[index][key];
          }
        });
      }
      await (0, _domUtil.nextTick)();
      this.isLoading = false;
    }
    async changeLanguage(model, locale, adapterOptions) {
      var _model$nonTranslatabl;
      this.isLoading = true;

      // Save previous model internaly if it does not exist yet
      if (model.availableLanguages.includes(model.language)) {
        this._updateChangedModels({
          model
        });
      }
      const nonTranslatableProperties = [];
      if ((model === null || model === void 0 || (_model$nonTranslatabl = model.nonTranslatablePropertyKeys) === null || _model$nonTranslatabl === void 0 ? void 0 : _model$nonTranslatabl.length) > 0) {
        model.nonTranslatablePropertyKeys.forEach(key => {
          nonTranslatableProperties.push({
            key: key,
            value: model[key]
          });
        });
      }
      const availableLanguages = model.availableLanguages;
      // Reload model
      this.uiAppSettings.setLocale(locale);

      // Set language
      model.language = locale;

      // Set available languages again to make sure it gets re-rendered for nested models
      model.availableLanguages = null;
      model.availableLanguages = availableLanguages;
      const index = this.changedModels.findIndex(existingModel => existingModel.modelName === model._internalModel.modelName && existingModel.id === model.id && existingModel.language === model.language && existingModel.isDeletedLanguage === false);

      // Find model in changed models and apply changes
      if (index !== -1) {
        Object.keys(this.changedModels[index]).forEach(key => {
          if (key !== 'id' && key !== 'modelName' && key !== 'isDeleted' && key !== 'isDeletedLanguage' && key !== 'isNew') {
            model[key] = this.changedModels[index][key];
          }
        });
      } else {
        // Rollback is needed, otherwise properties are not updated correctly
        model.rollbackAttributes();
        let _adapterOptions = {
          campaignId: this.modelId,
          type: model._internalModel.modelName,
          useNewApi: true
        };
        _adapterOptions = _objectSpread(_objectSpread({}, _adapterOptions), adapterOptions);
        await model.reload({
          adapterOptions: _adapterOptions
        });
      }
      nonTranslatableProperties.forEach(_ref => {
        let {
          key,
          value
        } = _ref;
        model[key] = value;
      });
      await (0, _domUtil.nextTick)();
      this.isLoading = false;
    }
    _findInternalModelCopy(model, language) {
      const modelName = model !== null && model !== void 0 && model.modelName ? model.modelName : model._internalModel.modelName;
      const modelLanguage = language ? language : model.language;
      return this.changedModels.find(oldModel => oldModel.modelName === modelName && oldModel.id === model.id && oldModel.language === modelLanguage);
    }
    async _updateAvailableLanguages(model, availableLanguages, adapterOptions) {
      for (const lang of availableLanguages) {
        // Check if model is already fetched
        const changedModel = this._findInternalModelCopy(model, lang);
        if (changedModel) {
          Object.keys(changedModel).forEach(key => {
            if (key !== 'id' && key !== 'modelName' && key !== 'isDeleted' && key !== 'isDeletedLanguage' && key !== 'isNew') {
              model[key] = changedModel[key];
            }
          });
        } else {
          // Model not found rollback and reload the model
          model.rollbackAttributes();
          this.uiAppSettings.setLocale(lang);
          model.language = lang;
          let _adapterOptions = {
            campaignId: this.modelId,
            type: model._internalModel.modelName,
            useNewApi: true
          };
          _adapterOptions = _objectSpread(_objectSpread({}, _adapterOptions), adapterOptions);
          await model.reload({
            adapterOptions: _adapterOptions
          });
        }

        // Update availableLanguages and save model
        model.availableLanguages = availableLanguages;
        this._updateChangedModels({
          model
        });
      }
    }

    /**
     * Keeps track of the changes which are applied to a model by saving
     * them inside the property "changedModels"
     *
     * @function _updateChangedModels
     * @param {Object} model The updated model
     * @param {String} property The updated property
     * @param {Boolean} isAddLanguage Whether a language was added
     * @param {Boolean} isRemoveLanguage Whether a language was removed
     * @param {String} language The language of the model (needed when model language is deleted)
     */
    _updateChangedModels(_ref2) {
      let {
        model,
        modelKey,
        property,
        language,
        isAddLanguage = false,
        isRemoveLanguage = false,
        oldValue,
        newValue
      } = _ref2;
      // Create copy of model
      const modelCopy = model.getProperties(...Object.keys(model.constructor.proto()));
      modelCopy.id = model.id;
      modelCopy.modelName = model._internalModel.modelName;
      modelCopy.changes = [];
      modelCopy.language = language || model.language;
      modelCopy.isDeletedLanguage = isRemoveLanguage;
      modelCopy.isDeleted = model.isDeleted;
      modelCopy.isNew = model.isNew || isAddLanguage;

      // Find internal model copy
      const oldModelCopy = this._findInternalModelCopy(modelCopy);

      // If there is an old version of this model saved and
      // isNew is set then the model should use this value
      if (oldModelCopy) {
        modelCopy.isNew = oldModelCopy.isNew || modelCopy.isNew;
      }

      // Extract change (old and new value)
      const change = model.changedAttributes()[property];

      // Push change into changes array if there was a change
      if (change && change.length || oldValue || newValue || typeof oldValue === 'boolean' && typeof newValue === 'boolean') {
        const oldChange = oldModelCopy === null || oldModelCopy === void 0 ? void 0 : oldModelCopy.changes.find(change => change.key === `${oldModelCopy.modelName}.${property}`);
        let _oldValue = null;
        if (oldChange) {
          _oldValue = oldChange.old;
        } else {
          if (oldValue || typeof oldValue === 'boolean') {
            _oldValue = oldValue;
          } else if ((change === null || change === void 0 ? void 0 : change.length) > 0) {
            _oldValue = change[0];
          }
        }
        let _newValue = null;
        if (newValue || typeof newValue === 'boolean') {
          _newValue = newValue;
        } else if ((change === null || change === void 0 ? void 0 : change.length) > 0) {
          _newValue = change[1];
        }
        let type = modelCopy.isNew ? 'added' : 'changed';
        if (property === 'state' || property === 'active') {
          type = _newValue === 'active' || _newValue === true ? 'activated' : 'deactivated';
        }
        modelCopy.changes.push({
          key: `${modelCopy.modelName}.${property}`,
          modelKey: modelKey,
          type: type,
          old: _oldValue,
          new: _newValue
        });
      }

      // Find internal model copies
      const changedModels = this.changedModels.filter(existingModel => existingModel.modelName === modelCopy.modelName && existingModel.id === modelCopy.id && (model.isDeleted || existingModel.language === modelCopy.language));

      // Iterate over internal model copies and merge changes
      changedModels.forEach(changedModel => {
        // Filter out old changes of the same property
        changedModel.changes = changedModel.changes.filter(change => {
          var _modelCopy$changes$;
          return (change === null || change === void 0 ? void 0 : change.key) !== ((_modelCopy$changes$ = modelCopy.changes[0]) === null || _modelCopy$changes$ === void 0 ? void 0 : _modelCopy$changes$.key);
        });

        // Merge new and old changes
        modelCopy.changes = modelCopy.changes.concat(changedModel.changes);

        // Remove change if old and new value is the same
        modelCopy.changes = modelCopy.changes.filter(change => change.old != change.new);

        // Delete old model copy
        const index = this.changedModels.indexOf(changedModel);
        if (!this.changedModels[index].isDeletedLanguage && !this.changedModels[index].isDeleted) {
          this.changedModels.splice(index, 1);
        }
      });

      // Remove all previous changes if model is deleted
      if (modelCopy.isDeleted || isRemoveLanguage) {
        modelCopy.changes = [];
      }

      // Whether a model is deleted which was just created. We dont keep track
      // of such a model, since the state is the same as in the beginning
      const isDeleteNewModel = (oldModelCopy === null || oldModelCopy === void 0 ? void 0 : oldModelCopy.isNew) && (modelCopy.isDeleted || modelCopy.isDeletedLanguage);
      if (!isDeleteNewModel) {
        this.changedModels.push(modelCopy);
      }
    }
    _cleanUp() {
      this.transactionId = null;
      this.changedModels = [];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "transactionId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "changedModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "modelId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
});