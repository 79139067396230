define("additive-mc/components/amc-campaign/email-campaign/configuration-item/yeardate", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/service", "@glimmer/tracking", "@ember/object", "date-fns"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _service, _tracking, _object, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex">
    <UiSelect
      @class="pr1"
      @label={{t "global.dates.months" value=1}}
      @selected={{get (array-find this._selectedMonth this._monthOptions) "label"}}
      @options={{this._monthOptions}}
      @onChange={{this._onChangeMonth}}
      @objectKey="label"
      @isReadOnly={{this.isReadOnly}}
      as |option item|
    >
      <option.item @value={{item.value}}>
        {{item.label}}
      </option.item>
    </UiSelect>
  
    <UiSelect
      @label={{t "global.dates.days" value=1}}
      @selected={{get (array-find this._selectedDay this._dayOptions) "label"}}
      @options={{this._dayOptions}}
      @onChange={{this._onChangeDay}}
      @objectKey="label"
      @isReadOnly={{this._isDaySelectReadOnly}}
      as |option item|
    >
      <option.item @value={{item.value}}>
        {{item.label}}
      </option.item>
    </UiSelect>
  </div>
  */
  {
    "id": "SM5pU3Q/",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@class\",\"@label\",\"@selected\",\"@options\",\"@onChange\",\"@objectKey\",\"@isReadOnly\"],[\"pr1\",[28,[37,1],[\"global.dates.months\"],[[\"value\"],[1]]],[28,[37,2],[[28,[37,3],[[30,0,[\"_selectedMonth\"]],[30,0,[\"_monthOptions\"]]],null],\"label\"],null],[30,0,[\"_monthOptions\"]],[30,0,[\"_onChangeMonth\"]],\"label\",[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1,2]]]]],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@label\",\"@selected\",\"@options\",\"@onChange\",\"@objectKey\",\"@isReadOnly\"],[[28,[37,1],[\"global.dates.days\"],[[\"value\"],[1]]],[28,[37,2],[[28,[37,3],[[30,0,[\"_selectedDay\"]],[30,0,[\"_dayOptions\"]]],null],\"label\"],null],[30,0,[\"_dayOptions\"]],[30,0,[\"_onChangeDay\"]],\"label\",[30,0,[\"_isDaySelectReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"item\"]],null,[[\"@value\"],[[30,4,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,4,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[3,4]]]]],[1,\"\\n\"],[13]],[\"option\",\"item\",\"option\",\"item\"],false,[\"ui-select\",\"t\",\"get\",\"array-find\"]]",
    "moduleName": "additive-mc/components/amc-campaign/email-campaign/configuration-item/yeardate.hbs",
    "isStrictMode": false
  });
  let AmcCampaignEmailCampaignConfigurationItemYearDataeComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignEmailCampaignConfigurationItemYearDataeComponent extends _component2.default {
    get _isDaySelectReadOnly() {
      return this.isReadOnly || !this._selectedMonth;
    }
    get _dayOptions() {
      if (!this._selectedMonth) {
        return [];
      }
      const year = new Date().getFullYear(); // Optional: Adjust for leap years if required

      const daysInMonth = new Date(year, this._selectedMonth, 0).getDate();
      const days = new Array(daysInMonth).fill(null).map((_, index) => {
        return {
          label: index + 1,
          value: index + 1
        };
      });
      return days;
    }

    /**
     * Returns given month number as long month string
     *
     * @function _getLongMonthByNumber
     * @param {Number} month The month number (1, 2, 5, 12...)
     */
    _getLongMonthByNumber(month) {
      if (!month) {
        return null;
      }
      try {
        /* Dummy date */
        const date = new Date(2020, 5, 5, 12);
        date.setMonth(month - 1);
        return (0, _dateFns.format)(date, 'MMMM', {
          locale: this.uiLocale.dateFnsLocale
        });
      } catch (error) {
        return null;
      }
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiLocale", _descriptor, this);
      /**
       * The configuration-item
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor2, this);
      /**
       * Whether its read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      /**
       * Whether the label should be hidden
       *
       * @argument isPlain
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPlain", _descriptor4, this);
      /**
       * Select options for month
       *
       * @property _dayOptions
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_monthOptions", _descriptor5, this);
      /**
       * The selected day
       *
       * @property _selectedDay
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_selectedDay", _descriptor6, this);
      /**
       * The selected month
       *
       * @property _selectedMonth
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_selectedMonth", _descriptor7, this);
      /**
       * On change event
       *
       * @function onChange
       * @public
       */
      _initializerDefineProperty(this, "onChange", _descriptor8, this);
      let resource = {};
      if (this.item && this.item.value) {
        const [month, day] = this.item.value.split('-');
        resource = {
          month: Number(month),
          day: Number(day)
        };
      } else {
        resource = {
          month: null,
          day: null
        };
      }
      this._selectedDay = resource.day;
      this._selectedMonth = resource.month;
      this._monthOptions = new Array(12).fill(null).map((_, index) => {
        return {
          value: index + 1,
          label: this._getLongMonthByNumber(index + 1)
        };
      });
    }
    change(selectedMonth, selectedDay) {
      if (!selectedMonth | !selectedDay) {
        return;
      }

      /* ensure it uses 2 digits (1 -> 01) */
      const month = ('0' + selectedMonth).slice(-2);
      const day = ('0' + selectedDay).slice(-2);
      this.onChange(`${month}-${day}`);
    }
    _onChangeDay(value) {
      this._selectedDay = value;
      this.change(this._selectedMonth, value);
    }
    _onChangeMonth(value) {
      this._selectedDay = null;
      this._selectedMonth = value;
      this.change(value, this._selectedDay);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPlain", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_monthOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_selectedDay", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_selectedMonth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onChangeDay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChangeDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onChangeMonth", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChangeMonth"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignEmailCampaignConfigurationItemYearDataeComponent);
});