define("additive-mc/controllers/instance/campaigns/email/detail", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/object/computed", "@glimmer/tracking", "ember-concurrency", "@additive-apps/utils/utils/errors"], function (_exports, _controller, _object, _service, _computed, _tracking, _emberConcurrency, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsEmailDetailController = _exports.default = (_dec = (0, _computed.alias)('uiAppSettings.languages.contentLanguages'), _dec2 = (0, _computed.alias)('fetchEmailMeta.isRunning'), _dec3 = (0, _computed.not)('currentUser.isManager'), _dec4 = (0, _computed.not)('currentUser.isAdditiveUser'), _dec5 = (0, _computed.not)('currentUser.isPartnerUser'), _dec6 = (0, _computed.or)('isLoading', 'isError', '_isLoading'), _dec7 = (0, _computed.alias)('uiAppSettings.currentLocale'), _dec8 = (0, _computed.or)('_isMember', '_isManaged'), _dec9 = (0, _object.computed)('router.currentRouteName'), _dec10 = (0, _object.computed)('model.orderedConfigurations'), _dec11 = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      yield this.model.fetchMeta.perform(this.currentLocale);
    } catch (error) {
      this.isError = true;
    }
  }), _class = class InstanceCampaignsEmailDetailController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor7, this);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor8, this);
      /**
       * the email campaign
       *
       * @argument model
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "model", _descriptor9, this);
      /**
       * the changeset
       *
       * @argument _changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor10, this);
      _initializerDefineProperty(this, "isPublishDialog", _descriptor11, this);
      _initializerDefineProperty(this, "isEditorOpened", _descriptor12, this);
      /**
       * whether an error occured
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor13, this);
      /**
       * whether the modal to send a test email is open
       *
       * @property _isTestEmailDialogOpen
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isTestEmailDialogOpen", _descriptor14, this);
      _initializerDefineProperty(this, "_isLoading", _descriptor15, this);
      /**
       * Current selected language
       *
       * @property _currentLanguage
       * @type {String}
       * @private
       */
      _defineProperty(this, "_currentLanguage", 'de');
      _initializerDefineProperty(this, "_contentLanguages", _descriptor16, this);
      _initializerDefineProperty(this, "isLoading", _descriptor17, this);
      _initializerDefineProperty(this, "_isMember", _descriptor18, this);
      _initializerDefineProperty(this, "_isNotAdditiveUser", _descriptor19, this);
      _initializerDefineProperty(this, "_isNotPartnerUser", _descriptor20, this);
      _initializerDefineProperty(this, "hideContent", _descriptor21, this);
      _initializerDefineProperty(this, "currentLocale", _descriptor22, this);
      _initializerDefineProperty(this, "_isReadOnly", _descriptor23, this);
      /**
       * Fetches the meta
       * @function fetchEmailMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchEmailMeta", _descriptor24, this);
    }
    /**
     * returns true if the active route is the vouchers list route
     *
     * @computed isEmailsRoute
     * @return {Boolean} whether the vouchers route is active or not
     */
    get isEmailsRoute() {
      return this.router.isActive('instance.campaigns.email.detail.index');
    }
    get isActive() {
      if (this.model.active) {
        return this.model.active;
      }
      return false;
    }
    get _configurationKeys() {
      const configurations = this.model && this.model.orderedConfigurations;
      return configurations && Object.keys(configurations) || [];
    }
    get _isManaged() {
      if (!this.uiAppSettings.managed) {
        return false;
      }
      if (this.uiAppSettings.managedByPartner) {
        return this._isNotPartnerUser && this._isNotAdditiveUser;
      }
      return this._isNotAdditiveUser;
    }

    /**
     * temporary check to use new view and content wizard only for dedicated campaigns
     */
    get _isNewView() {
      var _this$model;
      return (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.isNewStructure;
    }
    back() {
      this.transitionToRoute('instance.campaigns.email');
    }
    transitionToCreate() {
      if (this.router.isActive('instance.campaigns.email.detail.index')) {
        this.transitionToRoute('instance.campaigns.email.detail.index.create');
      } else if (this.router.isActive('instance.campaigns.email.detail.email-series')) {
        this.transitionToRoute('instance.campaigns.email.detail.email-series.create');
      }
    }
    async onConfigurationSave() {
      try {
        await this.model.save({
          adapterOptions: {
            useNewApi: false
          }
        });
      } catch (e) {
        this.model.rollbackAttributes();
        if (e) {
          const errorDetail = (0, _errors.getAdapterError)(e);
          if (errorDetail.custom) {
            this.uiDialog.showAlert(this.intl.t('campaigns.configurations.errors.custom.title'), errorDetail && errorDetail.custom);
            return;
          }
        }
        this.uiDialog.showError();
      }
    }
    async openPublishDialog(isEditorOpened) {
      this.isEditorOpened = false;
      if (!this.uiTransactionManager.hasTransactionId) {
        try {
          await this.uiTransactionManager.beginTransaction({
            type: this.model.type,
            id: this.model.id
          });
        } catch (error) {
          this.uiDialog.showError();
          return;
        }
      }
      if (typeof isEditorOpened == 'boolean' && isEditorOpened) {
        this.isEditorOpened = true;
      }
      this.isPublishDialog = true;
    }
    async rollbackModel() {
      var _this$model2;
      // Rollback email-series and associated emails
      const emailsSeriesCopy = ((_this$model2 = this.model) === null || _this$model2 === void 0 || (_this$model2 = _this$model2.emailSeries) === null || _this$model2 === void 0 ? void 0 : _this$model2.toArray()) || [];
      emailsSeriesCopy.forEach(emailSeriesModel => {
        var _emailSeriesModel$ema;
        // Create a shallow copy of the emails array to avoid mutation issues
        const emailsCopy = (emailSeriesModel === null || emailSeriesModel === void 0 || (_emailSeriesModel$ema = emailSeriesModel.emails) === null || _emailSeriesModel$ema === void 0 ? void 0 : _emailSeriesModel$ema.toArray()) || [];
        emailsCopy.forEach(emailModel => {
          emailModel === null || emailModel === void 0 || emailModel.rollbackAttributes();
        });
        emailSeriesModel === null || emailSeriesModel === void 0 || emailSeriesModel.rollbackAttributes();
      });

      // Rollback voucher
      if (this.model.contribution) {
        this.model.contribution.rollbackAttributes();
      }

      // Rollback landing-page
      if (this.model.landingPage) {
        this.model.landingPage.rollbackAttributes();
      }
      if (this.model.language !== this.uiAppSettings.currentLocale) {
        this.uiAppSettings.setLocale(this.model.language);
      }
      this.model.rollbackAttributes();
      await this.model.reload({
        adapterOptions: {
          useNewApi: true
        }
      });
    }
    async closePublishDialog(isSaved, isNew) {
      if (isSaved) {
        if (!isNew) {
          await this.rollbackModel();
        }
        this.isPublishDialog = false;
        return;
      }
      if (this.isEditorOpened) {
        this.router.transitionTo('instance.campaigns.email.detail.index.edit', this.model.id);
      } else {
        await this.rollbackModel();
        await this.uiTransactionManager.discardTransaction();
      }
      this.isPublishDialog = false;
    }
    goToVoucherDetail(id) {
      this.router.transitionTo('instance.campaigns.email.detail.index.voucher', id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isPublishDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEditorOpened", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_isTestEmailDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_contentLanguages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "_isMember", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "_isNotAdditiveUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "_isNotPartnerUser", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "hideContent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "currentLocale", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "_isReadOnly", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isEmailsRoute", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isEmailsRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_configurationKeys", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "_configurationKeys"), _class.prototype), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "fetchEmailMeta", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToCreate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onConfigurationSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onConfigurationSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openPublishDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openPublishDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rollbackModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "rollbackModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closePublishDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closePublishDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToVoucherDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToVoucherDetail"), _class.prototype), _class);
});